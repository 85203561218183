<template>
  <b-card no-body title="Inventory">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="onLoadAllInventoryItems">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table
        responsive
        :fields="fields"
        :items="getAllInventoryItems"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        sort-icon-left
        no-local-sorting
      >
        <template #cell(label)="data">
          <b-link v-model="data.detailsShowing" @click="data.toggleDetails">
            {{ data.value }}
          </b-link>
          <template v-if="data.item.listing">
            <feather-icon icon="ListIcon" size="24" title="Has a listing, expand to view" v-b-tooltip.hover.right.v-success class="text-success float-right"></feather-icon>
          </template>
        </template>

        <template #cell(productName)="data">
          {{ data.value }}
          <template v-if="data.item.itemStrainName">
            <br />
            <b>Strain: </b> {{ data.item.itemStrainName }}
          </template>
        </template>

        <template #cell(productCategory)="data">
          {{ data.item.productCategoryName }} <br />
          <b>Type: </b>{{ data.item.productCategoryType }}
        </template>
        <template #cell(quantity)="data"> {{ data.value }} {{ data.item.unitOfMeasureAbbreviation }} </template>
        <template #cell(facilityName)="data">
          {{ data.item.facilityName }}<br />
          <small>{{ data.item.facilityLicenseNumber }}</small>
        </template>
        <template #cell(labTestingState)="data">
          {{ data.value }}
          <span v-if="data.value !== 'NotSubmitted' && data.value !== 'NotRequired'">
            <br /><br />
            {{ showDateOnly(data.item.labTestingStateDate) }}
          </span>
        </template>
        <!-- full detail on click -->
        <template #row-details="row">
          <b-card>
            <b>Guuid: </b>{{ row.item.guuid }} <br />
            <b>Facility Guid: </b>{{ row.item.facilityGuid }} <br />
            <br />
            <template v-if="row.item.listing">
              <b>Listing: </b>{{ row.item.listingName }} <br />
              <b>Description: </b>{{ row.item.listingDescription }} <br />
              <b>Uid: </b>{{ row.item.listingId }} <br />
            </template>
          </b-card>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>
        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { getAllInventoryItems, filterBy, setPerPage, getLoading, onLoadAllInventoryItems, getTotalRows, getPerPage, sortBy, sortOrder } from "@/views/inventory/inventoryRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { toMoneyUS } from "@/service/NumberService";

import { onMounted, ref, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";
export default {
  name: "AllInventory",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  props: {
    inventoryRow: null
  },
  setup(props, { root }) {
    const currentPage = ref(1);
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const fields = [
      // 'mark_resolved',
      {
        key: "label",
        label: "Tag",
        sortable: true
      },
      {
        key: "productName",
        label: "Product",
        sortable: true
      },
      {
        key: "productCategory",
        label: "Category",
        sortable: true
      },

      "quantity",
      {
        key: "packagedDate",
        label: "Packaged",
        sortable: true,
        formatter: (value) => {
          if (!value) {
            return "";
          }
          return moment(value).format("YYYY-MM-DD");
        }
      },
      {
        key: "facilityName",
        label: "Facility"
      },
      {
        key: "labTestingState",
        label: "COA",
        sortable: true
      }
    ];

    const isSortDesc = ref(false);

    watch(filterBy, (newValue, oldValue) => {
      initialLoadInventory();
    });

    watch(sortBy, (newValue, oldValue) => {
      console.log("sob", newValue);
      initialLoadInventory();
    });

    watch(isSortDesc, (newValue, oldValue) => {
      sortOrder.value = newValue ? "desc" : "asc";
      initialLoadInventory();
    });

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");
    const showDateOnly = (date) => moment(date).format("YYYY-MM-DD");

    const showAsMoney = (amt) => toMoneyUS(amt);

    const initialLoadInventory = async () => onLoadAllInventoryItems({ prevPage: false, nextPage: false, reset: true });

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initialLoadInventory();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllInventoryItems({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllInventoryItems({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllInventoryItems({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    onMounted(() => {
      if (props.inventoryRow) {
        filterBy.value = props.inventoryRow.facilityLicense;
      } else {
        filterBy.value = "";
      }
    });

    return {
      getAllInventoryItems,
      filterBy,
      setPerPage,
      getLoading,
      onLoadAllInventoryItems,
      getTotalRows,
      getPerPage,
      onPageChanged,
      perPageChanged,
      showDateInFormat,
      showDateOnly,
      currentPage,
      pageOptions,
      fields,
      showAsMoney,
      isSortDesc,
      sortBy
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
</style>
