import { ref, computed } from "@vue/composition-api";

import { requestLoadInventory } from "@/service/InventoryService";

const allInventoryItems = ref([]);
export const filterBy = ref(null);
const perPage = ref(10);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getAllInventoryItems = computed(() => allInventoryItems.value);
export const sortBy = ref("label");
export const sortOrder = ref("asc");

function getSort() {
  switch (sortBy.value) {
    case "productName":
      return "product_name";
    case "productCategory":
      return "product_category_name";
    case "packagedDate":
      return "packaged_date";
    case "labTestingState":
      return "lab_testing_state";
    default:
      return sortBy.value;
  }
}

export const onLoadAllInventoryItems = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  console.log("Sort is ", sortBy);
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    sort: getSort(),
    sortOrder: sortOrder.value,
    filter: {
      search: filterBy.value || null
    }
  };

  loading.value = true;
  try {
    const {
      data: { inventory, pagination }
    } = await requestLoadInventory(reqData); // backend returns inventory on a list called "samples"

    if (inventory) {
      allInventoryItems.value.splice(0, allInventoryItems.value.length);
      allInventoryItems.value.push(...inventory);
    } else {
      allInventoryItems.value.splice(0, allInventoryItems.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};
